<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import { BaseInputText, BaseButton, BaseSelect, BaseTextarea } from '@edsights/ui-core';
import ButtonBack from '@/components-deprecated/ButtonBack.vue';
import {
    isWebChatbotEnabled,
    isWebChatbotLeadCaptureSettingsEnabled,
    isWebChatbotSettingsPageEnabled
} from '@/lib/feature-flag';
import { findById, updateSchoolWebChatbotSettings } from '@/api/school';
import Alerts from '@/lib/alerts';
import {
    WEB_CHATBOT_SCREEN_POSITION,
    WEB_CHATBOT_DEFAULT_MASCOT_URL,
    FALLBACK_MASCOT_NAME,
    WEB_CHATBOT_DEFAULT_INTRO_MESSAGE
} from './constants.js';
const webChatbotScreenPositionOptions = Object.values(WEB_CHATBOT_SCREEN_POSITION);
import MascotImage from './components/MascotImage.vue';
import InputSwitch from '@/components-deprecated/global/v2/InputSwitch.vue';
const store = useStore();
const isLoading = ref(false);
const isSaving = ref(false);
const uploadedMascotImageDataUrl = ref(null);
const isStaff = computed(() => {
    return store.state.user.isStaff;
});
// The settings are set to some defaults.
// Once the school's settings are loaded, this will get updated with their customizations
const defaultSettings = {
    color: '#038BFF',
    webChatbotScreenPosition: WEB_CHATBOT_SCREEN_POSITION.BOTTOM_RIGHT.value,
    mascotUrl: WEB_CHATBOT_DEFAULT_MASCOT_URL,
    webChatbotMascotWidthPixels: 40,
    webChatbotMascotHeightPixels: 40,
    webChatbotLeadCaptureEnabled: false,
    webChatbotLeadCaptureProspectiveStudentEmails: '',
    webChatbotLeadCaptureAppliedAdmittedStudentEmails: '',
    webChatbotIntroMessage: WEB_CHATBOT_DEFAULT_INTRO_MESSAGE
        .replace('{{ mascotName }}', store.state?.user?.schoolRef?.mascotName || FALLBACK_MASCOT_NAME)
};
const settings = ref({
    color: defaultSettings.color,
    webChatbotScreenPosition: defaultSettings.webChatbotScreenPosition,
    mascotUrl: defaultSettings.mascotUrl,
    webChatbotMascotWidthPixels: defaultSettings.webChatbotMascotWidthPixels,
    webChatbotMascotHeightPixels: defaultSettings.webChatbotMascotHeightPixels,
    webChatbotLeadCaptureEnabled: defaultSettings.webChatbotLeadCaptureEnabled,
    webChatbotLeadCaptureProspectiveStudentEmails:
        defaultSettings.webChatbotLeadCaptureProspectiveStudentEmails,
    webChatbotLeadCaptureAppliedAdmittedStudentEmails:
        defaultSettings.webChatbotLeadCaptureAppliedAdmittedStudentEmails,
    webChatbotIntroMessage: defaultSettings.webChatbotIntroMessage
});
async function save() {
    try {
        isSaving.value = true;
        await updateSchoolWebChatbotSettings({
            schoolId: store.state.user.schoolRef.id,
            mascotImageDataUrl: uploadedMascotImageDataUrl.value,
            color: settings.value.color || defaultSettings.color,
            webChatbotMascotWidthPixels: (
                settings.value.webChatbotMascotWidthPixels ||
                defaultSettings.webChatbotMascotWidthPixels
            ).toString(),
            webChatbotMascotHeightPixels: (
                settings.value.webChatbotMascotHeightPixels ||
                defaultSettings.webChatbotMascotHeightPixels
            ).toString(),
            webChatbotScreenPosition:
                settings.value.webChatbotScreenPosition || defaultSettings.webChatbotScreenPosition,
            ...(showLeadCaptureSection.value
                ? {
                      webChatbotLeadCaptureEnabled: (
                          settings.value.webChatbotLeadCaptureEnabled ||
                          defaultSettings.webChatbotLeadCaptureEnabled
                      ).toString(),
                      webChatbotLeadCaptureProspectiveStudentEmails:
                          settings.value.webChatbotLeadCaptureProspectiveStudentEmails ||
                          defaultSettings.webChatbotLeadCaptureProspectiveStudentEmails,
                      webChatbotLeadCaptureAppliedAdmittedStudentEmails:
                          settings.value.webChatbotLeadCaptureAppliedAdmittedStudentEmails ||
                          defaultSettings.webChatbotLeadCaptureAppliedAdmittedStudentEmails
                  }
                : {}),
            webChatbotIntroMessage:
                settings.value.webChatbotIntroMessage ||
                defaultSettings.webChatbotIntroMessage,
            errorHandlerOptions: {
                rethrow: true,
                showAlert: true,
                overrideMessage: 'Error saving web chatbot settings.'
            }
        });
        Alerts.alert({
            type: 'success',
            message: 'Settings saved successfully.'
        });
    } finally {
        isSaving.value = false;
    }
}

// Note: all school settings are currently strings.
// When loading them, we convert some to other types
// for use with the UI.
// They get re-converted to strings when saving.
async function loadSchoolWebChatbotSettings() {
    const school = await findById(store.state.user.schoolRef.id, true);

    if (school.color) {
        settings.value.color = school.color;
    }

    if (school.webChatbotScreenPosition) {
        settings.value.webChatbotScreenPosition = school.webChatbotScreenPosition;
    }

    if (school.webChatbotMascotWidthPixels) {
        settings.value.webChatbotMascotWidthPixels = Number(school.webChatbotMascotWidthPixels);
    }

    if (school.webChatbotMascotHeightPixels) {
        settings.value.webChatbotMascotHeightPixels = Number(school.webChatbotMascotHeightPixels);
    }

    if (school.mascotUrl) {
        settings.value.mascotUrl = school.mascotUrl;
    }

    if (school.webChatbotLeadCaptureEnabled) {
        settings.value.webChatbotLeadCaptureEnabled =
            school.webChatbotLeadCaptureEnabled === 'true';
    }

    if (school.webChatbotLeadCaptureProspectiveStudentEmails) {
        settings.value.webChatbotLeadCaptureProspectiveStudentEmails =
            school.webChatbotLeadCaptureProspectiveStudentEmails;
    }

    if (school.webChatbotLeadCaptureAppliedAdmittedStudentEmails) {
        settings.value.webChatbotLeadCaptureAppliedAdmittedStudentEmails =
            school.webChatbotLeadCaptureAppliedAdmittedStudentEmails;
    }

    if(school.webChatbotIntroMessage) {
        settings.value.webChatbotIntroMessage = school.webChatbotIntroMessage;
    }
}

// Since this page can be navigated to directly, we must check if it should show:
const showPage = ref(false);
const showLeadCaptureSection = ref(false);
onMounted(async () => {
    try {
        isLoading.value = true;
        const webChatbotEnabled = await isWebChatbotEnabled(store.state.user.schoolRef.id);
        const settingsPageEnabled = webChatbotEnabled
            ? await isWebChatbotSettingsPageEnabled(store.state.user.schoolRef.id)
            : false;

        if (webChatbotEnabled && settingsPageEnabled) {
            showPage.value = true;
            showLeadCaptureSection.value = await isWebChatbotLeadCaptureSettingsEnabled(
                store.state.user.schoolRef.id
            );
            await loadSchoolWebChatbotSettings();
        }
    } finally {
        isLoading.value = false;
    }
});
</script>

<template>
    <div class="manage-web-chatbot container">
        <ButtonBack />

        <LoadingSpinner v-if="isLoading" />
        <div v-if="showPage">
            <div class="box">
                <h1 class="h2">Web Chatbot Settings</h1>
                <p>
                    Below are the web chatbot configuration settings. This page is "view only" so if
                    you need to make edits, reach out to your EdSights point of contact.
                </p>

                <section class="section-container">
                    <h2 class="h3">Mascot Image</h2>

                    <div class="row-container">
                        <MascotImage
                            :isStaff="isStaff"
                            v-model:uploadedMascotImageDataUrl="uploadedMascotImageDataUrl"
                            :mascotUrl="settings.mascotUrl"
                            v-model:webChatbotMascotWidthPixels="
                                settings.webChatbotMascotWidthPixels
                            "
                            v-model:webChatbotMascotHeightPixels="
                                settings.webChatbotMascotHeightPixels
                            "
                            :defaultSettings="defaultSettings"
                        />
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Color</h2>

                    <div class="row-container">
                        <div class="color">
                            <BaseInputText
                                :readOnly="!isStaff"
                                v-model.trim="settings.color"
                                label="Primary Color"
                                :placeholder="defaultSettings.color"
                            />
                            <div
                                class="color-preview"
                                :style="{ backgroundColor: settings.color }"
                            />
                        </div>
                    </div>
                </section>
                <section class="section-container">
                    <h2 class="h3">Positioning</h2>
                    <div class="row-container">
                        <BaseSelect
                            :readOnly="!isStaff"
                            v-model="settings.webChatbotScreenPosition"
                            label="Position on Website"
                            :options="webChatbotScreenPositionOptions"
                        />
                    </div>
                </section>
                <section class="section-container" v-if="showLeadCaptureSection">
                    <h2 class="h3">Lead Capture Functionality</h2>
                    <div class="row-container">
                        <div class="lead-capture">
                            <InputSwitch
                                v-model="settings.webChatbotLeadCaptureEnabled"
                                :disabled="!isStaff"
                                :label="
                                    `Lead Capture ${
                                        settings.webChatbotLeadCaptureEnabled ? 'ON' : 'OFF'
                                    }`
                                "
                            />

                            <div class="lead-capture-emails">
                                <BaseInputText
                                    class="email-input"
                                    label="Prospective Student Notification Email(s)"
                                    :readOnly="!isStaff"
                                    v-model="settings.webChatbotLeadCaptureProspectiveStudentEmails"
                                    placeholder="Notification emails separated by a comma"
                                />
                                <BaseInputText
                                    class="email-input"
                                    label="Applied/Admitted Student Notification Email(s)"
                                    :readOnly="!isStaff"
                                    v-model="
                                        settings.webChatbotLeadCaptureAppliedAdmittedStudentEmails
                                    "
                                    placeholder="Notification emails separated by a comma"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section-container" v-if="!settings.webChatbotLeadCaptureEnabled">
                    <div class="intro-message-container">
                        <h2 class="h3">Introduction Message</h2>
                        <BaseTextarea
                            class="intro-message"
                            label="Customize Introduction Message"
                            v-model="settings.webChatbotIntroMessage"
                            placeholder="This is the chatbot's first message"
                            :readOnly="!isStaff"
                        />
                    </div>
                </section>
                <div v-if="isStaff" class="button-container">
                    <BaseButton @click="save" label="Save" :loading="isSaving" />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.manage-web-chatbot {
    // todo: ui-core needs this style on labels
    // adding here for now
    :deep(label) {
        font-weight: bold;
    }
    .color {
        display: flex;
        align-items: end;
        .color-preview {
            margin-left: 10px;
            border-radius: 5px;
            width: 3.16rem;
            height: 3.16rem;
        }
    }
    .button-container {
        display: flex;
        justify-content: flex-end;
    }
}
.section-container {
    margin-top: 25px;
    margin-left: 25px;
}

.row-container {
    display: flex;
    justify-content: space-between;
}
.h3 {
    color: black;
    font-size: initial;
}

.lead-capture {
    width: 60%;
    margin-top: 10px;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    .lead-capture-emails {
        display: flex;
        flex-direction: column;
        .email-input {
            width: 400px;
            padding-bottom: 10px;
        }
    }
}

.intro-message-container {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.intro-message {
    width: 100% !important;
}
</style>
