export const WEB_CHATBOT_SCREEN_POSITION = {
    TOP_LEFT: {
        value: 'TOP_LEFT',
        label: 'Top left'
    },
    TOP_RIGHT: {
        value: 'TOP_RIGHT',
        label: 'Top right'
    },
    BOTTOM_RIGHT: {
        value: 'BOTTOM_RIGHT',
        label: 'Bottom right'
    },
    BOTTOM_LEFT: {
        value: 'BOTTOM_LEFT',
        label: 'Bottom left'
    }
};

export const WEB_CHATBOT_DEFAULT_MASCOT_URL =
    'https://edsights-mascot-images-production.s3.amazonaws.com/default-mascot/robot.png';

export const FALLBACK_MASCOT_NAME = 'Chatbot';

export const WEB_CHATBOT_DEFAULT_INTRO_MESSAGE = `Hi there! My name is {{ mascotName }}, your personal helper bot. What can I help with today?`;
