export const ANSWER_OPTION_TEXT = {
    MATCH_ALL_ANSWERS: { value: 'MATCH_ALL_ANSWERS', label: 'Match all answers', displayOrder: 0 },
    YES: { value: 'YES', label: 'Yes', displayOrder: 1 },
    NO: { value: 'NO', label: 'No', displayOrder: 2 },
    0: { value: '0', label: '0', displayOrder: 3 },
    1: { value: '1', label: '1', displayOrder: 4 },
    2: { value: '2', label: '2', displayOrder: 5 },
    3: { value: '3', label: '3', displayOrder: 6 },
    4: { value: '4', label: '4', displayOrder: 7 },
    5: { value: '5', label: '5', displayOrder: 8 },
    6: { value: '6', label: '6', displayOrder: 9 },
    7: { value: '7', label: '7', displayOrder: 10 },
    8: { value: '8', label: '8', displayOrder: 11 },
    9: { value: '9', label: '9', displayOrder: 12 },
    10: { value: '10', label: '10', displayOrder: 13 }
};
