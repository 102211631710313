<template>
    <div class="chatbot-flow-builder-container container">
        <div class="header-container">
            <div class="description">
                <h2>{{ headerText }}</h2>
                <p>
                    Select a group of students using the filter option or by importing a student
                    list.
                </p>
            </div>
        </div>
        <div class="content">
            <div class="section filters">
                <TabsContainer
                    @tab-selected="toggleFilterType"
                    ref="filterTabs"
                    :disableTabSwitching="isReadOnlyMode"
                >
                    <Tab id="studentFilter" label="Filter">
                        <StudentFilter
                            @filter-changed="onStudentFilterChange"
                            @filter-loading="setShowLoadingOverlay"
                            :studentFilter="form.studentFilter"
                            :tags="tags"
                            :messagingTracks="messagingTracks"
                            :schools="schools"
                            :riskBuckets="riskBuckets"
                            :readOnly="isReadOnlyMode"
                            :isMultiMessageTrackEnabled="isMultiMessageTrackEnabled"
                        />
                    </Tab>
                    <Tab id="studentList" label="Import Student List">
                        <StudentList
                            :studentList="this.form.studentList"
                            :schools="schools"
                            @list-changed="onStudentListChange"
                            @list-loading="setShowLoadingOverlay"
                            :readOnly="isReadOnlyMode"
                        />
                    </Tab>
                </TabsContainer>
                <h2 v-if="selectedSchoolId && (isCreateMode || isEditMode)">
                    This flow will be sent to
                    <span class="student-count">{{ this.studentCount }} students</span>.
                </h2>
                <h2 v-else-if="isReadOnlyMode">
                    You are viewing this workflow in View Only mode.
                </h2>
            </div>
            <template v-if="selectedSchoolId">
                <div class="section">
                    <h3>TITLE</h3>
                    <div class="input-container title">
                        <TextInput
                            placeholder="Enter a title for your Chatbot Flow."
                            v-model="form.template.name"
                            :disabled="isReadOnlyMode"
                        />
                    </div>
                    <div class="input-container">
                        <BaseCheckbox
                            v-model="form.touchPoint.isCustom"
                            label="Custom Touchpoint"
                            :disabled="isReadOnlyMode"
                        />
                    </div>
                    <div class="input-container">
                        <BaseCheckbox
                            v-if="isNudgeEnabled"
                            v-model="form.flow.nudge"
                            label="Nudge students who don't complete this flow"
                            :disabled="isReadOnlyMode"
                        />
                    </div>
                </div>
                <div class="section">
                    <h3>DESCRIPTION</h3>
                    <ChatbotFlowTextArea
                        v-model="form.touchPoint.description"
                        :bindings="touchpointDescriptionBindings"
                        :readOnly="isReadOnlyMode"
                        placeholder="Enter a description for your Campaign."
                        :rows="5"
                    />
                </div>
                <div class="section">
                    <h3>CHATBOT OUTGOING MESSAGE</h3>
                    <QuestionRecursive
                        v-model="form.firstQuestion"
                        :questionBindings="questionBindings"
                        :interventionBindings="interventionBindings"
                        :placeholder="
                            `Write the first message that the chatbot is going to send to students.`
                        "
                        :tags="tags"
                        :riskBuckets="riskBuckets"
                        isFirstQuestion
                        :readOnly="isReadOnlyMode"
                        :resourceBindingTags="resourceBindingTags"
                        :isFiniteAnswerOptionsFlagEnabled="isFiniteAnswerOptionsFlagEnabled"
                    />
                </div>
                <div class="section">
                    <h3>LABELS</h3>
                    <ChatbotFlowLabels v-model="form.labels" :readOnly="isReadOnlyMode" />
                </div>
                <div class="section">
                    <h3>DATE</h3>
                    <div class="input-container date">
                        <DateTimeInput
                            v-model="flowDateAsHTMLDateTime"
                            :disabled="isReadOnlyMode"
                        />
                    </div>
                </div>

                <div class="save" v-if="!isReadOnlyMode">
                    <template v-if="isCreateMode">
                        <template v-if="isReusableTemplate">
                            <Button @click="create">Save as Template</Button>
                        </template>
                        <template v-else>
                            <Button style-type="secondary" @click="create">Save as Draft</Button>
                            <Button @click="showScheduleFlowModal">Save and Schedule</Button>
                        </template>
                    </template>
                    <template v-if="isEditMode">
                        <template v-if="isReusableTemplate">
                            <Button @click="update">Save Template</Button>
                        </template>
                        <template v-else>
                            <Button style-type="secondary" @click="update">Save as Draft</Button>
                            <Button @click="showScheduleFlowModal">Save and Schedule</Button>
                        </template>
                    </template>
                </div>
            </template>
            <div v-if="showLoadingOverlay" class="loading-overlay">
                <div class="spinner-container">
                    <LoadingSpinner />
                </div>
            </div>
            <ScheduleFlowModal
                v-if="scheduleFlowModalData.showModal"
                :chatbotFlow="scheduleFlowModalData.chatbotFlow"
                :saving="scheduleFlowModalData.saving"
                @onClose="closeScheduleFlowModal"
                @onSave="scheduleChatbotFlow"
            />
        </div>
    </div>
</template>

<script>
import TextInput from '@/components-deprecated/global/TextInput';
import StudentFilter from './components/StudentFilter';
import { BaseCheckbox } from '@edsights/ui-core';
import ChatbotFlowTextArea from './components/ChatbotFlowTextArea';
import * as ResourceNeedApi from '@/api/resource-need';
import * as SchoolApi from '@/api/school';
import QuestionRecursive from '@/views/ChatbotFlowBuilder/components/QuestionRecursive';
import ChatbotFlowLabels from '@/views/ChatbotFlowBuilder/components/ChatbotFlowLabels';
import DateTimeInput from '@/components-deprecated/global/DateTimeInput';
import Button from '@/components-deprecated/global/Button';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';
import { createFlow, findById, updateChatbotFlow, validateChatbotFlow } from '@/api/chatbot-flow';
import { listTagsForSchool } from '@/api/tag';
import { listMessagingTracks } from '@/api/messaging-tracks';
import RiskBucket from '@/services/riskBuckets';
import { formatHTMLDateTimeToISO, formatISOToHTMLDateTime } from '@/lib/dates';
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import StudentList from '@/views/ChatbotFlowBuilder/components/StudentList';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import {
    isChatbotBuilderFiniteAnswerOptionsFlagEnabled,
    isDbSchoolFeatureFlagEnabled
} from '@/lib/feature-flag';
import ScheduleFlowModal from '@/views/staff/ChatbotFlowList/components/ScheduleFlowModal';
import { listSchools } from '@/api/school';
const { validate: uuidValidate, v4 } = require('uuid');

const defaultQuestionBindings = { '{{student.first_name}}': '{{student.first_name}}' };
const defaultInterventionBindings = { '{{student_count}}': '{{student_count}}' };

const messagingTrackFL = 'STUDENT_FILTER_MULTI_MESSAGING_TRACKS';
const nudgeFL = 'CHATBOT_FLOW_NUDGE';

export default {
    name: 'ChatbotFlowBuilder',
    components: {
        ScheduleFlowModal,
        LoadingSpinner,
        StudentList,
        TabsContainer,
        Tab,
        Button,
        DateTimeInput,
        ChatbotFlowLabels,
        QuestionRecursive,
        StudentFilter,
        TextInput,
        BaseCheckbox,
        ChatbotFlowTextArea
    },
    data() {
        return {
            saving: false,
            filterTypes: {
                studentFilter: 'studentFilter',
                studentList: 'studentList'
            },
            filterType: 'studentFilter',
            touchpointDescriptionBindings: {},
            questionBindings: {},
            interventionBindings: {},
            resourceBindingTags: {},
            form: {
                studentFilter: {},
                studentList: {},
                firstQuestion: {
                    text: '',
                    answerOptions: []
                },
                flow: {
                    date: null,
                    status: CHATBOT_FLOW_STATUS.DRAFT.value,
                    nudge: false
                },
                template: {
                    name: '',
                    reusable: false
                },
                touchPoint: {
                    description: '',
                    isCustom: false
                },
                labels: []
            },
            tags: [],
            messagingTracks: [],
            isMultiMessageTrackEnabled: false,
            isNudgeEnabled: false,
            schools: [],
            riskBuckets: [],
            showLoadingOverlay: false,
            scheduleFlowModalDataDefaults: {
                showModal: false,
                chatbotFlow: null,
                saving: false
            },
            scheduleFlowModalData: { ...this.scheduleFlowModalDataDefaults },
            isFiniteAnswerOptionsFlagEnabled: false
        };
    },
    created() {
        this.loadRiskBuckets();
        this.loadSchools();
        this.checkIfMultiMessagingTracksEnabled();
        this.checkIfFiniteOptionsFlagEnabled();
        this.checkIfNudgeEnabled();
        // This component is accessed through 4 named routes:
        // ChatbotFlowBuilderCreate, ChatbotFlowBuilderCreateTemplate, ChatbotFlowBuilderEdit, or ChatbotFlowBuilderCreateFromTemplate.

        // On the ChatbotFlowBuilderCreate route, the component's default data is used.
        // Load/set the appropriate data for the other 3 routes:
        const routeName = this.$route.name;
        if (routeName === 'ChatbotFlowBuilderCreateTemplate') {
            this.form.template.reusable = true;
        } else if (
            routeName === 'ChatbotFlowBuilderEdit' &&
            uuidValidate(this.$route.params.flowId)
        ) {
            this.loadDataByFlowId(this.$route.params.flowId);
        } else if (
            routeName === 'ChatbotFlowBuilderCreateFromTemplate' &&
            uuidValidate(this.$route.params.templateFlowId)
        ) {
            this.loadDataByTemplateFlowId(this.$route.params.templateFlowId);
        }
    },
    methods: {
        async create({ schedule = false }) {
            try {
                this.showLoadingOverlay = true;

                const payload = this.formatPayload({ schedule });

                await createFlow({
                    payload,
                    errorHandlerOptions: {
                        rethrow: true,
                        enableAlert: true
                    }
                });

                this.navigateToFlowList();
            } finally {
                this.showLoadingOverlay = false;
            }
        },
        async update({ schedule = false }) {
            try {
                this.showLoadingOverlay = true;
                const payload = this.formatPayload({ schedule });

                await updateChatbotFlow(this.form.flow.id, payload, {
                    rethrow: true,
                    enableAlert: true
                });

                this.navigateToFlowList();
            } finally {
                this.showLoadingOverlay = false;
            }
        },
        removeAnswerOptionTags(question) {
            if (question && typeof question === 'object') {
                const questionCopy = { ...question };
                questionCopy.answerOptions = questionCopy.answerOptions.map(option => ({
                    ...option,
                    tags: []
                }));

                questionCopy.answerOptions.forEach(option => {
                    this.removeAnswerOptionTags(option.nextQuestion);
                });

                return questionCopy;
            }

            return null;
        },
        // Depending on status, either returns the flow's date or the null date placeholder.
        getDateForStatus(flowDate, flowStatus) {
            return (flowStatus === CHATBOT_FLOW_STATUS.DRAFT.value ||
                flowStatus === CHATBOT_FLOW_STATUS.CANCELED.value) &&
                !flowDate
                ? DRAFT_NULL_DATE_PLACEHOLDER
                : flowDate;
        },
        formatPayload({ schedule }) {
            // If this is a reusable template, we automatically set the flow's status to "CANCELED"
            // so it doesn't show up on the Flows page, only the Templates page.
            const status = this.isReusableTemplate
                ? CHATBOT_FLOW_STATUS.CANCELED.value
                : schedule
                ? CHATBOT_FLOW_STATUS.SCHEDULED.value
                : CHATBOT_FLOW_STATUS.DRAFT.value;

            const payload = {
                status,
                date: this.getDateForStatus(this.form.flow.date, status),
                firstQuestion: this.formatAnswerOptionsForPayload(this.form.firstQuestion),
                template: this.form.template,
                touchPoint: this.form.touchPoint,
                labels: this.form.labels,
                nudge: this.form.flow.nudge
            };

            if (
                this.filterType === 'studentFilter' &&
                typeof this.form.studentFilter === 'object'
            ) {
                payload.schoolId = this.form.studentFilter.schoolId;
                payload.studentFilter = {
                    id: this.form.studentFilter.id,
                    phases: this.form.studentFilter.phases,
                    firstTimeEngager: this.form.studentFilter.firstTimeEngager,
                    riskCategory: this.form.studentFilter.riskCategory,
                    riskBucketId: this.form.studentFilter.riskBucketId,
                    riskLevels: this.form.studentFilter.riskLevels,
                    tagIds: this.form.studentFilter.tagIds,
                    schoolId: this.form.studentFilter.schoolId,
                    tagRelationship: this.form.studentFilter.tagRelationship
                };

                if (this.isMultiMessageTrackEnabled) {
                    payload.studentFilter.messagingTrackIds = this.form.studentFilter.messagingTrackIds;
                } else {
                    payload.studentFilter.messagingTrackId = this.form.studentFilter.messagingTrackIds;
                }
            }

            if (this.filterType === 'studentList' && typeof this.form.studentList === 'object') {
                payload.schoolId = this.form.studentList.schoolId;
                payload.studentList = this.form.studentList.studentIds;
            }

            return payload;
        },
        onSelectFilterType(value) {
            this.filterType = value;
        },
        onStudentFilterChange(studentFilter) {
            this.form.studentFilter = studentFilter;
        },
        onStudentListChange(studentList) {
            this.form.studentList = studentList;
        },
        async loadTags(schoolId) {
            this.tags = await listTagsForSchool({
                schoolId,
                filters: { includePublic: true },
                errorHandlerOptions: {
                    rethrow: false,
                    enableAlert: true
                }
            });
        },
        async checkIfMultiMessagingTracksEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.isMultiMessageTrackEnabled = await isDbSchoolFeatureFlagEnabled(
                    this.$store.state.user.schoolRef.id,
                    messagingTrackFL
                );
            } else {
                this.isMultiMessageTrackEnabled = false;
            }
        },
        async checkIfFiniteOptionsFlagEnabled() {
            this.isFiniteAnswerOptionsFlagEnabled = await isChatbotBuilderFiniteAnswerOptionsFlagEnabled(
                this.$store.state.user.schoolRef.id
            );
        },
        async checkIfNudgeEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.isNudgeEnabled = await isDbSchoolFeatureFlagEnabled(
                    this.$store.state.user.schoolRef.id,
                    nudgeFL
                );
            } else {
                this.isNudgeEnabled = false;
            }
        },
        async loadMessagingTracks(schoolId) {
            const messagingTracks = await listMessagingTracks(schoolId);
            this.messagingTracks = messagingTracks.map(mt => {
                return { label: mt.displayName, value: mt.id };
            });
        },
        async loadBindings(schoolId) {
            const [resourceNeedTemplateBindingsTags, schoolTemplateBindings] = await Promise.all([
                ResourceNeedApi.getResourceNeedsMappedByBindingKey(schoolId),
                SchoolApi.findTemplateBindingsBySchoolId(schoolId)
            ]);

            this.questionBindings = {
                ...defaultQuestionBindings,
                ...schoolTemplateBindings,
                ...resourceNeedTemplateBindingsTags.drivers
            };

            this.interventionBindings = {
                ...defaultInterventionBindings,
                ...schoolTemplateBindings,
                ...Object.keys(resourceNeedTemplateBindingsTags.drivers).reduce(
                    (acc, bindingKey) => {
                        if (bindingKey.startsWith('{{resource.name.')) {
                            return {
                                ...acc,
                                [bindingKey]: resourceNeedTemplateBindingsTags.drivers[bindingKey]
                            };
                        }

                        return acc;
                    },
                    {}
                )
            };

            this.resourceBindingTags = resourceNeedTemplateBindingsTags.tags;

            // touchpoints use the same bindings as interventions
            this.touchpointDescriptionBindings = this.interventionBindings;
        },
        async loadRiskBuckets() {
            // todo: get from Node instead of django
            this.riskBuckets = (await RiskBucket.api.list()).results;
        },
        async loadSchools() {
            this.schools = (await listSchools({
                page_size: 500,
                isActive: true
            })).results;
        },
        toggleFilterType(filterTabId) {
            this.filterType = filterTabId;
        },
        navigateToFlowList() {
            this.$router.push({
                name: 'ChatbotFlowList'
            });
        },
        async loadDataByFlowId(flowId) {
            this.showLoadingOverlay = true;
            if (typeof flowId === 'string') {
                const data = await findById({
                    id: flowId,
                    includeFull: true,
                    errorHandlerOptions: { rethrow: false, enableAlert: true }
                });
                this.setFormData(data);
                this.showLoadingOverlay = false;
            }
        },
        async loadDataByTemplateFlowId(templateFlowId) {
            this.showLoadingOverlay = true;
            if (typeof templateFlowId === 'string') {
                const data = await findById({
                    id: templateFlowId,
                    includeFull: true,
                    errorHandlerOptions: { rethrow: false, enableAlert: true }
                });
                const newFlowData = this.convertTemplateFlowDataToNewFlow(data);
                this.setFormData(newFlowData);
                this.showLoadingOverlay = false;
            }
        },
        // clears all primary ids and school ids from a template's flow data and sets its status to "DRAFT",
        // so it can be used as if creating a new flow
        convertTemplateFlowDataToNewFlow(data) {
            if (data && typeof data === 'object') {
                const dataCopy = {
                    ...data,
                    status: CHATBOT_FLOW_STATUS.DRAFT.value
                };
                delete dataCopy.id;
                if (dataCopy.template) {
                    delete dataCopy.template.id;

                    // We are creating a flow from a reusable template, so the new flow should
                    // not be a reusable template. This way the flow only shows up on the Flows
                    // page, and not the Templates page.
                    dataCopy.template.reusable = false;
                }
                if (dataCopy.touchPoint) {
                    delete dataCopy.touchPoint.id;
                }
                if (dataCopy.studentFilter) {
                    delete dataCopy.studentFilter.id;
                    delete dataCopy.studentFilter.schoolId;
                }
                if (dataCopy.studentList) {
                    delete dataCopy.studentList.schoolId;
                }
                dataCopy.firstQuestion = this.clearQuestionIds(dataCopy.firstQuestion);

                return dataCopy;
            }

            return null;
        },
        clearQuestionIds(question) {
            if (question && typeof question === 'object') {
                const questionCopy = { ...question };
                delete questionCopy.id;
                if (Array.isArray(questionCopy.answerOptions)) {
                    questionCopy.answerOptions = questionCopy.answerOptions.map(option => {
                        if (option) {
                            delete option.id;
                            if (option.answerScore) {
                                delete option.answerScore.id;
                            }

                            if (option.intervention) {
                                delete option.intervention.id;
                            }

                            option.nextQuestion = this.clearQuestionIds(option.nextQuestion);
                        }

                        return option;
                    });
                }

                return questionCopy;
            }

            return null;
        },
        setFormData(data) {
            if (data && typeof data === 'object') {
                this.form.flow = {
                    id: data.id,
                    status: data.status,
                    date: data.date,
                    nudge: data.nudge
                };
                this.form.template = data.template;
                this.form.touchPoint = data.touchPoint;
                this.form.labels = data.labels;
                this.form.firstQuestion = this.addTabIdsToAnswerOptions(data.firstQuestion);

                // Clear out the date if it's a draft or cancelled flow saved with a null date placeholder.
                if (
                    (data.status === CHATBOT_FLOW_STATUS.DRAFT.value ||
                        data.status === CHATBOT_FLOW_STATUS.CANCELED.value) &&
                    data.date === DRAFT_NULL_DATE_PLACEHOLDER
                ) {
                    this.form.flow.date = null;
                }

                if (data.studentFilter) {
                    this.form.studentFilter = data.studentFilter;
                    this.filterType = this.filterTypes.studentFilter;
                } else if (data.studentList) {
                    this.form.studentList = data.studentList;
                    this.filterType = this.filterTypes.studentList;
                    this.$refs.filterTabs.selectTab(this.filterTypes.studentList);
                }
            }
        },
        // add a tabId to each answer option - used as a unique identifier when displaying answer options in Tabs
        addTabIdsToAnswerOptions(question) {
            if (question && typeof question === 'object') {
                const questionCopy = { ...question };
                questionCopy.answerOptions = questionCopy.answerOptions.map(option => ({
                    ...option,
                    tabId: v4(),
                    nextQuestion: this.addTabIdsToAnswerOptions(option.nextQuestion)
                }));

                return questionCopy;
            }

            return null;
        },
        formatAnswerOptionsForPayload(question) {
            if (question && typeof question === 'object') {
                const questionCopy = { ...question };
                questionCopy.answerOptions = questionCopy.answerOptions.map(option => {
                    const optionCopy = { ...option };

                    // if toDelete is true, the answer option was removed (tab was deleted by user).
                    // translate this so the BE knows to delete the option.
                    if (optionCopy.toDelete === true) {
                        optionCopy.text = '';
                    }

                    // remove toDelete and tabId - they're  only used by the UI and should be removed before sending to the API
                    delete optionCopy.toDelete;
                    delete optionCopy.tabId;

                    optionCopy.nextQuestion = this.formatAnswerOptionsForPayload(
                        optionCopy.nextQuestion
                    );
                    return optionCopy;
                });

                return questionCopy;
            }

            return null;
        },
        async showScheduleFlowModal() {
            // Same payload shape as when creating/updating a flow:
            const payload = this.formatPayload({ schedule: true });

            this.showLoadingOverlay = true;
            const validation = await validateChatbotFlow({
                chatbotFlow: {
                    id: this.form.flow.id || null,
                    ...payload
                },
                errorHandlerOptions: {
                    enableAlert: true,
                    rethrow: false
                }
            });

            if (validation && validation.isValid === true) {
                this.scheduleFlowModalData = {
                    ...this.scheduleFlowModalDataDefaults,
                    showModal: true,
                    chatbotFlow: this.getChatbotFlowFormattedForScheduleFlowModal()
                };
            }

            this.showLoadingOverlay = false;
        },
        closeScheduleFlowModal() {
            this.scheduleFlowModalData = { ...this.scheduleFlowModalDataDefaults };
        },
        async scheduleChatbotFlow() {
            this.closeScheduleFlowModal();

            if (this.isCreateMode) {
                await this.create({ schedule: true });
            } else if (this.isEditMode) {
                await this.update({ schedule: true });
            }

            this.closeScheduleFlowModal();
        },
        getSelectedMessagingTracks(studentFilter) {
            if (!studentFilter) {
                return [];
            }

            const rawMessagingTrackIds = [];
            if ('messagingTrackId' in studentFilter) {
                rawMessagingTrackIds.push(studentFilter.messagingTrackId);
            } else if (Array.isArray(studentFilter.messagingTrackIds)) {
                rawMessagingTrackIds.push(...studentFilter.messagingTrackIds);
            } else if (typeof studentFilter.messagingTrackIds === 'string') {
                // while we are transitioning from single to multi messaging track filtering,
                // messagingTrackIds is used but is just a string (not an array) when the flag is off.
                rawMessagingTrackIds.push(studentFilter.messagingTrackIds);
            }
            const selectedMessagingTrackIds = new Set(rawMessagingTrackIds);
            return this.messagingTracks.filter(mt => selectedMessagingTrackIds.has(mt.value));
        },
        getChatbotFlowFormattedForScheduleFlowModal() {
            const data = {
                date: this.form.flow.date,
                template: {
                    name: this.form.template.name
                },
                schoolName: this.schools.find(school => school.id === this.selectedSchoolId).name
            };

            if (this.filterType === 'studentList') {
                data.studentList = this.form.studentList;
            } else if (this.filterType === 'studentFilter') {
                data.studentFilter = {
                    firstTimeEngager: this.form.studentFilter.firstTimeEngager,
                    phases: this.form.studentFilter.phases,
                    riskCategory: this.form.studentFilter.riskCategory,
                    riskLevels: this.form.studentFilter.riskLevels,
                    studentCount: this.form.studentFilter.studentCount,
                    tagRelationship: this.form.studentFilter.tagRelationship,
                    riskBucketRef: this.form.studentFilter.riskBucketId
                        ? this.riskBuckets.find(
                              bucket => bucket.id === this.form.studentFilter.riskBucketId
                          )
                        : null,
                    tagsRef: this.form.studentFilter.tagIds.reduce((acc, tagId) => {
                        const tag = this.tags.find(tag => tag.id === tagId);
                        if (tag) {
                            acc.push({
                                id: tag.id,
                                name: tag.name
                            });
                        }
                        return acc;
                    }, []),
                    messagingTracksRef: this.getSelectedMessagingTracks(this.form.studentFilter)
                };
            }

            return data;
        },
        setShowLoadingOverlay(loading) {
            this.showLoadingOverlay = loading;
        }
    },
    computed: {
        selectedSchoolId() {
            if (
                this.filterType === 'studentFilter' &&
                this.form.studentFilter &&
                this.form.studentFilter.schoolId
            ) {
                return this.form.studentFilter.schoolId;
            } else if (
                this.filterType === 'studentList' &&
                this.form.studentList &&
                this.form.studentList.schoolId
            ) {
                return this.form.studentList.schoolId;
            }

            return null;
        },
        studentCount() {
            let count = 0;

            if (
                this.filterType === 'studentFilter' &&
                typeof this.form.studentFilter === 'object' &&
                typeof this.form.studentFilter.studentCount === 'number'
            ) {
                count = this.form.studentFilter.studentCount;
            } else if (
                this.filterType === 'studentList' &&
                typeof this.form.studentList === 'object' &&
                typeof this.form.studentList.studentCount === 'number'
            ) {
                count = this.form.studentList.studentCount;
            }

            return count;
        },
        isCreateMode() {
            // flow does not have an id yet - it's new and is being created
            return this.form && this.form.flow && !this.form.flow.id;
        },
        isEditMode() {
            // flow has an id and is not read-only - it exists and is being edited
            return (
                !this.isReadOnlyMode &&
                this.form &&
                this.form.flow &&
                typeof this.form.flow.id === 'string'
            );
        },
        isReadOnlyMode() {
            // flow has an id and a status of "INITIATED" - it exists and is being viewed
            return (
                this.form &&
                this.form.flow &&
                typeof this.form.flow.id === 'string' &&
                this.form.flow.status === CHATBOT_FLOW_STATUS.INITIATED.value
            );
        },
        isReusableTemplate() {
            return this.form && this.form.template && this.form.template.reusable === true;
        },
        flowDateAsHTMLDateTime: {
            get() {
                return typeof this.form.flow === 'object'
                    ? formatISOToHTMLDateTime(this.form.flow.date)
                    : null;
            },
            set(newValue) {
                this.form.flow.date = newValue ? formatHTMLDateTimeToISO(newValue) : null;
            }
        },
        headerText() {
            return this.isReusableTemplate ? 'Draft a Campaign Template' : 'Draft a Campaign';
        }
    },
    watch: {
        selectedSchoolId(schoolId, oldSchoolId) {
            // if school has changed,
            // clear bindings and tags, since they are school-specific.
            if (
                typeof schoolId === 'string' &&
                typeof oldSchoolId === 'string' &&
                schoolId !== oldSchoolId
            ) {
                this.questionBindings = {};
                this.touchpointDescriptionBindings = {};
                this.interventionBindings = {};
                this.resourceBindingTags = {};
                this.tags = [];
                this.form.firstQuestion = this.removeAnswerOptionTags(this.form.firstQuestion);
            }

            // if school has been set for the first time or has changed,
            // load bindings and tags
            if (typeof schoolId === 'string' && schoolId !== oldSchoolId) {
                this.loadTags(schoolId);
                this.loadBindings(schoolId);
                this.loadMessagingTracks(schoolId);
                this.checkIfMultiMessagingTracksEnabled();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.chatbot-flow-builder-container {
    .header-container {
        margin-top: 1rem;

        .description {
            margin: 3rem 2.75rem 1rem 2.75rem;
        }
    }

    .content {
        margin: 0 2.75rem 3rem 2.75rem;
        position: relative;
        height: 100%;

        .section {
            h2,
            h3 {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            > * {
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .input-container {
                &.title {
                    width: 50%;
                }

                &.date {
                    width: 20rem;
                }
            }

            &.filters {
                :deep(.tabs) {
                    padding-bottom: 0;
                }
            }
        }
        .loading-overlay {
            position: absolute;
            z-index: 10000;
            cursor: wait;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .spinner-container {
                position: fixed;
                left: 50%;
                top: 50%;
            }
        }

        .student-count {
            color: $edsights-blue;
        }

        .save {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            > * {
                padding-left: 1rem;
            }
        }
    }

    .box {
        margin-bottom: 20px;
        overflow: visible;
    }
}
</style>
