<!--
This component provides a button to initiate a report request, waits for it to complete, and then
downloads the report.

To use this component with a new report type, do the following:

1. Add component to your template with the required reportType and reportOptions, if any (reportOptions is optional).
2. Add the new reportType to the prop validation and the switch statement in the onDownloadClick
   method. You can open a modal for further options, or start the download immediately. Follow the
   existing example(s).

Report Type Notes:
CHATBOT_CONVERSATIONS:
Available reportOptions:
   1. chatbotFlowId - Required string of the chatbot flow for which we want the report for.
   2. interventionTemplates - Optional array of template objects associated to the chatbot flow,
      with their id (number) and type (string). The filtering modal will narrow-down this list by
      'type' if any filter is selected, and then "interventionTemplateIds" will be sent to the API
      when requesting the report.
   3. touchpointId - Optional string of the touchpoint related to the chatbot flow. This is used in
      contexts where the interventionTemplates are unknown. This component will fetch the
      interventionTemplates and use them in the same way as if they were passed in directly (see #2).
   4. interventionTemplateId - Optional number id of a specific intervention template. This removes the
      "All who responded" option in the filtering modal, and adds the "Mentioned in this Intervention"
      option.

      Note that while interventionTemplates and touchpointIds are optional, at least one of them is required
      for filtering by intervention template type to work.
-->
<template>
    <div class="report-request-download-button-container">
        <button
            v-if="!loading"
            tabindex="0"
            class="download-link clickable"
            :aria-label="ariaLabel"
            @click="onDownloadClick"
        >
            {{ buttonText }}
            <svg class="icon-download" width="15px" height="15px" viewBox="0 0 20 20">
                <use xlink:href="#icon-download-unstyled" />
            </svg>
        </button>
        <LoadingSpinner v-if="loading" />
    </div>
</template>

<script>
import { nextTick } from 'vue';

import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { createReportRequest, getReportRequestDownloadUrlById } from '@/api/report-request';
import { subscribeAndExecuteOnEvents, unsubscribe } from '@/lib/realtime-messaging';
import { v4 } from 'uuid';
import { strictEqual } from 'assert';
import { downloadFileFromUrl } from '@/lib/file-helpers';
import { findTouchpointById } from '@/api/touchpoint';
import { formatISO, FORMATS } from '@/lib/dates';
import { DateTime } from 'luxon';
export default {
    name: 'ReportRequestDownloadButton',
    components: { LoadingSpinner },
    props: {
        reportType: {
            type: String,
            validator: value =>
                ['CHATBOT_CONVERSATIONS', 'STARFISH_ATTRIBUTES', 'STARFISH_NOTES'].includes(value),
            required: true
        },
        reportOptions: {
            type: Object,
            required: false
        },
        buttonText: {
            type: String,
            default: 'Download Report'
        },
        ariaLabel: {
            type: String,
            default: 'Download Report'
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        // Perform action by report type. Some reports may open a modal for further options,
        // others may just start the report creation/download immediately:
        async onDownloadClick() {
            if (this.reportType === 'CHATBOT_CONVERSATIONS') {
                if (this.reportOptions.skipFilters === true) {
                    const handler = this.chatbotConversationsHandler({
                        chatbotFlowId: this.reportOptions.chatbotFlowId,
                        cleanOutput: this.reportOptions.cleanOutput
                    });
                    await handler();
                } else {
                    await this.chatbotConversationsShowOptionsModal();

                    nextTick(() => {
                        const downloadReportModal = document.querySelector('.vm--container');
                        if (downloadReportModal) {
                            downloadReportModal.focus();
                        }
                    });
                }
                return;
            } else if (this.reportType === 'STARFISH_ATTRIBUTES') {
                // the attributes file needs no report options
                await this.createReportRequestAndSubscribe({});
                this.$modal.hide('modal-starfish-reports');
                return;
            } else if (this.reportType === 'STARFISH_NOTES') {
                const starfishErrors = this.generateStarfishErrors(this.reportOptions);
                if (starfishErrors.length === 0) {
                    await this.createReportRequestAndSubscribe({
                        options: this.reportOptions
                    });
                    this.$modal.hide('modal-starfish-reports');
                    return;
                } else {
                    this.loading = false;
                    this.$Alert.alert({
                        type: 'error',
                        message: starfishErrors.join('<br />'),
                        timeout: 15000
                    });
                    return;
                }
            }

            this.onError();
        },
        // Create report request and subscribe to receive a message when the report is ready:
        async createReportRequestAndSubscribe({ options }) {
            const generateReportChannel = 'edsights-ui-generate-report-{lookupKey}';

            // Create a unique lookupKey and use it in the channelName.
            const lookupKey = v4();
            const channelName = generateReportChannel.replace('{lookupKey}', lookupKey);

            try {
                // Subscribe to the channel and when the report is complete, fetch the
                // related downloadUrl, and download the file. Or, when the report fails, handle it.
                // This event binding is done before creating the report request below, in case the
                // report is generated too quickly and an event arrives before we are fully subscribed:
                await subscribeAndExecuteOnEvents({
                    channelName,
                    eventCallbacks: [
                        {
                            eventName: `report-request-complete`,
                            callback: async message => {
                                try {
                                    const messageObject = JSON.parse(message);
                                    const reportRequestId = messageObject.reportRequestId;

                                    const downloadUrl = await getReportRequestDownloadUrlById({
                                        id: reportRequestId
                                    });
                                    await downloadFileFromUrl(downloadUrl);
                                    this.loading = false;
                                } catch {
                                    this.onError();
                                }
                            }
                        },
                        {
                            eventName: `report-request-failed`,
                            callback: () => {
                                this.onError();
                            }
                        }
                    ]
                });

                // Create the request:
                await createReportRequest({
                    payload: {
                        reportType: this.reportType,
                        lookupKey,
                        optionsJson: JSON.stringify(options)
                    },
                    errorHandlerOptions: {
                        enableAlert: false,
                        rethrow: true
                    }
                });
            } catch (error) {
                // Unsubscribe, just in case subscribing was successful but the report request had
                // an error while being created:
                unsubscribe({ channelName });

                // Re-throw so this can be caught in any report handlers where it gets used.
                throw error;
            }
        },
        // Generic error handler to reset the loading state and alert the user:
        onError() {
            this.loading = false;
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem downloading the report.',
                timeout: 15000
            });
        },
        // CHATBOT_CONVERSATIONS - opens a modal for selecting filters:
        async chatbotConversationsShowOptionsModal() {
            try {
                strictEqual(
                    typeof this.reportOptions,
                    'object',
                    'this.reportOptions must be an object'
                );
                strictEqual(
                    typeof this.reportOptions.chatbotFlowId,
                    'string',
                    'this.reportOptions.chatbotFlowId must be a string'
                );

                const disabledStudentGroupItems = [];
                let interventionTemplates = [];
                let studentGroupItems = null;
                let initialSelectedStudentGroup = null;

                if (this.reportOptions.interventionTemplateId) {
                    studentGroupItems = [
                        { label: 'Mentioned in this Intervention', value: 'THIS_INTERVENTION' },
                        { label: 'Mentioned in Opportunities', value: 'ADMIN' },
                        { label: 'Mentioned in Actions Taken', value: 'CHATBOT' },
                        {
                            label: 'Mentioned in Opportunities OR Actions Taken',
                            value: 'ADMIN_OR_CHATBOT'
                        }
                    ];
                    initialSelectedStudentGroup = 'THIS_INTERVENTION';
                }

                // The interventionTemplates are either provided directly in this.reportOptions,
                // or a touchpointId is provided in order to fetch the interventionTemplates in
                // contexts where they aren't available (or, only a filtered set of them are available).
                // This is a concept from the v1 Reports setup.
                if (Array.isArray(this.reportOptions.interventionTemplates)) {
                    interventionTemplates = this.reportOptions.interventionTemplates;
                } else if (typeof this.reportOptions.touchpointId === 'string') {
                    this.loading = true;
                    const touchpoint = await findTouchpointById({
                        id: this.reportOptions.touchpointId,
                        returnRendered: false,
                        includeInterventionTemplates: true,
                        errorHandlerOptions: {
                            rethrow: false,
                            enableAlert: false
                        }
                    });

                    if (!touchpoint || !Array.isArray(touchpoint.interventionTemplates)) {
                        this.onError();
                        return;
                    }

                    interventionTemplates = touchpoint.interventionTemplates;
                    this.loading = false;
                }
                // disable the 'ADMIN' and 'CHATBOT' options if they are not found in the associated interventionTemplates
                ['ADMIN', 'CHATBOT'].forEach(interventionTemplateType => {
                    if (
                        !interventionTemplates.some(
                            template =>
                                typeof template === 'object' &&
                                template.type === interventionTemplateType
                        )
                    ) {
                        disabledStudentGroupItems.push(interventionTemplateType);
                    }
                });

                this.$modal.show('modal-report-download', {
                    showStudentGroupDropdown: true,
                    downloadReport: this.chatbotConversationsHandler({
                        chatbotFlowId: this.reportOptions.chatbotFlowId,
                        interventionTemplateId: this.reportOptions.interventionTemplateId,
                        cleanOutput: this.reportOptions.cleanOutput,
                        interventionTemplates
                    }),
                    disabledStudentGroupItems,
                    studentGroupItems,
                    initialSelectedStudentGroup,
                    showDeactivatedStudentCheckbox: true
                });
            } catch {
                this.onError();
            }
        },
        // CHATBOT_CONVERSATIONS - returns function that formats selections from modal (selectedTagCategories and studentGroup)
        // and creates/subscribes to the report:
        chatbotConversationsHandler({
            chatbotFlowId,
            cleanOutput,
            interventionTemplates,
            interventionTemplateId
        }) {
            return async ({
                selectedTagCategories,
                studentGroup,
                includeDeactivatedStudents
            } = {}) => {
                try {
                    this.loading = true;
                    let tagCategoryIds = [];
                    if (Array.isArray(selectedTagCategories)) {
                        tagCategoryIds = selectedTagCategories.map(category => category.id);
                    }

                    let interventionTemplateIds = [];
                    if (
                        studentGroup === 'THIS_INTERVENTION' &&
                        typeof interventionTemplateId === 'number'
                    ) {
                        interventionTemplateIds = [interventionTemplateId];
                    } else if (
                        studentGroup === 'ADMIN_OR_CHATBOT' &&
                        Array.isArray(interventionTemplates)
                    ) {
                        interventionTemplateIds = interventionTemplates.map(it => it.id);
                    } else if (
                        (studentGroup === 'ADMIN' || studentGroup === 'CHATBOT') &&
                        Array.isArray(interventionTemplates)
                    ) {
                        interventionTemplateIds = interventionTemplates
                            .filter(it => it.type === studentGroup)
                            .map(it => it.id);
                    }

                    const options = {
                        chatbotFlowId,
                        interventionTemplateIds,
                        tagCategoryIds,
                        cleanOutput
                    };

                    if (includeDeactivatedStudents) {
                        // unfortunately the server pattern and the ui pattern
                        // are best presented with booleans that read as the
                        // inverse of each other, so we do a simple translation
                        // here
                        options.onlyIncludeActiveStudents = false;
                    }

                    await this.createReportRequestAndSubscribe({ options });
                } catch (error) {
                    this.onError();
                }
            };
        },
        generateStarfishErrors(options) {
            const errors = [];

            try {
                strictEqual(typeof options, 'object', 'options should be an object');
            } catch (error) {
                errors.push('The date options are invalid.');
                // return here as everything that follows will fail
                return errors;
            }

            try {
                strictEqual(
                    typeof options.startDate,
                    'string',
                    'options.startDate should be a string'
                );
                if (formatISO(options.startDate, FORMATS.DATE) === '') {
                    throw new Error('Start date is not valid');
                }
            } catch (error) {
                errors.push('The start date is invalid.');
            }

            try {
                strictEqual(typeof options.endDate, 'string', 'options.endDate should be a string');
                if (formatISO(options.endDate, FORMATS.DATE) === '') {
                    throw new Error('End date is not valid');
                }
            } catch (error) {
                errors.push('The end date is invalid.');
            }

            // return here as everything that follows will fail
            if (errors.length > 0) {
                return errors;
            }

            // create some date objects for comparison/math
            const zone = { zone: 'America/New_York' };
            const start = DateTime.fromISO(options.startDate, zone);
            const end = DateTime.fromISO(options.endDate, zone);

            // is start date before end date
            if (start > end) {
                errors.push('The start date is after the end date.');
            }

            // is start date and end date within 365 days of each other
            const totalDiff = start.diff(end, 'days').toObject();
            if (totalDiff && totalDiff.days < -365) {
                errors.push('The start and end dates are greater than 365 days apart.');
            }

            return errors;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.report-request-download-button-container {
    .download-link {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        margin-left: 3rem;
        color: $primary-brand-color;
        background: transparent;
        border: 0;
        font-size: 12px;
        letter-spacing: 1px;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    .icon-download {
        stroke: $primary-brand-color;
        margin-left: 1rem;
    }
}
</style>
