<template>
    <div class="main-box">
        <SchoolDropDown @selected-school-id-updated="filterBySchool" />
        <div class="flows-toolbar">
            <span
                >A calendar view of all drafted, scheduled, and sent flows that have an assigned
                date.</span
            >
            <Button v-if="!loading" @click="createNew">New</Button>
        </div>
        <LoadingSpinner v-if="loading" />
        <div v-else class="calendar-wrapper">
            <div class="calendar-box">
                <FullCalendar
                    v-if="!loading"
                    :events="events"
                    @eventClick="itemClick"
                    @dayClick="dayClick"
                />
            </div>
            <div class="box calendar-details-box" v-if="selectedDay">
                <div class="title">
                    <h1>{{ selectedDay.title }}</h1>
                    <span class="close-button" @click="close">X</span>
                </div>
                <div class="flows-table">
                    <Table :data="selectedDay.flows">
                        <template v-slot:rows="{ rows }">
                            <template v-for="row in rows" :key="`row-${row.id}`">
                                <tr class="row">
                                    <td class="name">
                                        <div class="content">
                                            <img
                                                v-if="row.touchpointRef.isCustom"
                                                class="icon"
                                                alt="Feather representing a custom touchpoint"
                                                src="@/assets/icons/feather.png"
                                            />
                                            <img
                                                v-else
                                                class="icon"
                                                alt="EdSights logo representing a non-custom touchpoint"
                                                src="@/assets/icons/edsights-e.png"
                                            />
                                            <div>
                                                <div class="name-container">
                                                    <h3>
                                                        {{ row.templateRef.name }}
                                                    </h3>
                                                </div>
                                                <FlowDate
                                                    :date="row.date"
                                                    :flowId="row.id"
                                                    :flowStatus="row.status"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="status">
                                        <div class="content">
                                            <div
                                                class="status-indicator"
                                                :class="[CHATBOT_FLOW_STATUS[row.status].value]"
                                            />
                                            {{ CHATBOT_FLOW_STATUS[row.status].label }}
                                        </div>
                                        <div class="content">
                                            <div
                                                v-if="
                                                    row.status ===
                                                        CHATBOT_FLOW_STATUS.SCHEDULED.value ||
                                                        row.status ===
                                                            CHATBOT_FLOW_STATUS.DRAFT.value
                                                "
                                            >
                                                <span
                                                    v-if="!row.historical"
                                                    class="edit-link text-primary"
                                                    @click="detailClick(row, false)"
                                                    >EDIT</span
                                                >
                                                <span
                                                    class="cancel-link text-primary"
                                                    @click="openCancelFlowModal(row.id)"
                                                    >CANCEL</span
                                                >
                                            </div>
                                            <span
                                                v-if="
                                                    row.status ===
                                                        CHATBOT_FLOW_STATUS.INITIATED.value &&
                                                        !row.historical
                                                "
                                                class="view-link text-primary"
                                                @click="detailClick(row, true)"
                                                >VIEW</span
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </Table>
                </div>
            </div>
            <CancelFlowModal
                v-if="cancelFlowModalData.showModal"
                :flow="cancelFlowModalData.flow"
                @onClose="closeCancelFlowModal"
                @onSavedAsDraft="onSavedAsDraft"
                @onSavedAsCancelled="onSavedAsCancelled"
            />
        </div>
    </div>
</template>

<script>
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';
import { getChatbotFlows } from '@/api/chatbot-flow';
import FullCalendar from '@/components-deprecated/global/FullCalendar';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';
import Table from '@/components-deprecated/global/Table';
import FlowDate from '@/views/staff/ChatbotFlowList/components/FlowDate';
import CancelFlowModal from '@/views/staff/ChatbotFlowList/components/CancelFlowModal';
import { DateTime } from 'luxon';

export default {
    name: 'FlowsCalendar',
    components: {
        SchoolDropDown,
        FullCalendar,
        LoadingSpinner,
        Button,
        Table,
        FlowDate,
        CancelFlowModal
    },
    data() {
        return {
            flows: [],
            loading: false,
            selectedWorkflow: null,
            CHATBOT_FLOW_STATUS: CHATBOT_FLOW_STATUS,
            selectedDay: null,
            cancelFlowModalDataDefaults: {
                showModal: false,
                flow: null
            },
            cancelFlowModalData: { ...this.cancelFlowModalDataDefaults },
            schoolFilter: ''
        };
    },
    computed: {
        events() {
            function getColor(status) {
                if (status === CHATBOT_FLOW_STATUS.DRAFT.value) {
                    return 'red';
                } else if (status === CHATBOT_FLOW_STATUS.SCHEDULED.value) {
                    return 'green';
                } else if (status === CHATBOT_FLOW_STATUS.INITIATED.value) {
                    return 'grey';
                } else {
                    return '';
                }
            }

            let filteredFlows = this.flows;
            // filter by selected schools
            if (this.schoolFilter) {
                filteredFlows = this.flows.filter(item =>
                    // filter by school
                    typeof this.schoolFilter === 'string' && Array.isArray(item.schoolsRef)
                        ? item.schoolsRef.find(s => s.id === this.schoolFilter)
                        : true
                );
            }

            return filteredFlows.map(flow => ({
                id: flow.id,
                title: flow.templateRef.name,
                date: flow.date,
                backgroundColor: getColor(flow.status)
            }));
        }
    },
    async created() {
        try {
            this.loading = true;
            const data = await getChatbotFlows({
                statuses: [
                    CHATBOT_FLOW_STATUS.DRAFT.value,
                    CHATBOT_FLOW_STATUS.SCHEDULED.value,
                    CHATBOT_FLOW_STATUS.INITIATED.value
                ]
            });

            this.flows = data?.results ?? [];
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem loading the flows. Please try again.'
            });
        }
    },
    methods: {
        async filterBySchool(schoolId) {
            this.schoolFilter = schoolId;
        },
        itemClick(id) {
            const flow = this.flows.find(f => f.id === id);

            this.$router.push({
                name: 'ChatbotFlowBuilderEdit',
                params: { flowId: flow.id }
            });
        },
        dayClick(date) {
            this.selectedDay = {
                title: DateTime.fromJSDate(date)
                    .startOf('day')
                    .toLocaleString(DateTime.DATE_FULL),
                flows: this.getFlowsOnDay(date)
            };
        },
        datesAreSameDay(date1, date2) {
            // Ensure the dates are JS dates.
            const _date1 = new Date(date1);
            const _date2 = new Date(date2);

            // Get day from each date.
            const day1 = DateTime.fromJSDate(_date1).startOf('day');
            const day2 = DateTime.fromJSDate(_date2).startOf('day');

            return day1.valueOf() === day2.valueOf();
        },
        getFlowsOnDay(selectedDate) {
            return this.flows.filter(f => {
                if (f.date) {
                    return this.datesAreSameDay(selectedDate, f.date);
                }
                return false;
            });
        },
        createNew() {
            this.$router.push({
                name: 'ChatbotFlowBuilderCreate'
            });
        },
        close() {
            this.selectedDay = null;
        },
        detailClick(flow) {
            this.$router.push({
                name: 'ChatbotFlowBuilderEdit',
                params: { flowId: flow.id }
            });
        },
        openCancelFlowModal(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                const flow = this.flows.find(f => f.id === flowId);
                if (typeof flow === 'object') {
                    this.cancelFlowModalData = {
                        ...this.cancelFlowModalDataDefaults,
                        showModal: true,
                        flow
                    };
                }
            }
        },
        async closeCancelFlowModal() {
            this.cancelFlowModalData = this.cancelFlowModalDataDefaults;
        },
        onSavedAsDraft(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flows = this.flows.map(flow => {
                    if (flow.id === flowId) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.DRAFT.value
                        };
                    }

                    return flow;
                });
                this.selectedDay.flows = this.selectedDay.flows.map(flow => {
                    if (flow.id === flowId) {
                        return {
                            ...flow,
                            status: CHATBOT_FLOW_STATUS.DRAFT.value
                        };
                    }

                    return flow;
                });
            }
        },
        onSavedAsCancelled(flowId) {
            if (typeof flowId === 'string' && Array.isArray(this.flows)) {
                // close modal and update component data to reflect change
                this.closeCancelFlowModal();
                this.flows = this.flows.filter(flow => flow.id !== flowId);
                this.selectedDay.flows = this.selectedDay.flows.filter(flow => flow.id !== flowId);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.main-box {
    .flows-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
    }
    .calendar-wrapper {
        display: flex;
        width: 100%;
    }
    .calendar-box {
        flex-grow: 1;
        width: 70%;
    }
    .calendar-details-box {
        margin-left: 15px;
        width: 30%;
    }
    .title {
        top: 0;
        display: flex;
        justify-content: space-between;
    }
    .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: auto;
    }
    .flows-table {
        &:deep(.table-container table) {
            border-collapse: collapse;

            tbody {
                tr {
                    height: 120px;
                    &.row {
                        border-top: 1px solid $black-alpha-0-25;
                        &:first-of-type {
                            border-top: 0;
                        }
                        &:hover {
                            background-color: $white-blue;
                        }
                    }

                    td {
                        &.name {
                            .content {
                                display: flex;
                                align-items: center;
                                .icon {
                                    margin-right: 10px;
                                    height: 40px;
                                    width: 40px;
                                }

                                .name-container {
                                    display: flex;
                                    h3 {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        &.status {
                            .content {
                                display: flex;
                                align-items: center;
                                .status-indicator {
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 10px;
                                    margin-right: 5px;

                                    &.SCHEDULED {
                                        background-color: $green;
                                    }
                                    &.DRAFT {
                                        background-color: $red;
                                    }
                                    &.INITIATED {
                                        background-color: #bdbdbd;
                                    }
                                }

                                .edit-link {
                                    font-weight: bold;
                                    cursor: pointer;
                                }
                                .cancel-link {
                                    font-weight: bold;
                                    cursor: pointer;
                                    margin-left: 2rem;
                                }
                                .view-link {
                                    font-weight: bold;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
